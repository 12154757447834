<template>
  <div>
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-modal
        hide-footer
        id="modal-prevent-closing"
        ref="my-modal"
        :title="`Subir Archivo`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Tipo Documento"
          >
            <b-form-select
                v-model="file_send.type_document"
                :options="type_document_options"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Titulo"
          >
            <b-form-input
                v-model="file_send.title"
                placeholder="Titulo"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12" v-if="file_send.type_document === 'Indicaciones'">
          <b-form-group
              label="Ciclo"
          >
            <b-form-input
                @keypress="isNumber($event)"
                v-model="file_send.cycle"
                placeholder="Ciclo"
            />
          </b-form-group>

        </b-col>
        <b-col cols="4" class="mb-1" v-if="file_send.type_document === 'Indicaciones'">
          <b-form-checkbox
            v-model="file_send.fee"
          >
            Sin Honorarios
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" v-if="file_send.type_document === 'Indicaciones'">
          <b-form-group
            label="Honorarios medico"
          >
            <b-form-input
              @keypress="isNumber($event)"
              placeholder="Honorarios Medico"
              v-model="file_send.fee_cycle"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="file_send.type_document === 'Indicaciones'">
          <b-form-group>
            <h5>Fecha de Ciclo</h5>
            <flat-pickr
                v-model="file_send.date_cycle"
                class="form-control"
                :config="{ enableTime: false,dateFormat: 'Y-m-d',  locale: {
                  firstDayOfWeek: 1,
                  weekdays: {
                    shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                    longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                  },
                  months: {
                    shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                    longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                  }}}"
                      />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
              label="Archivo"
          >
            <b-form-file
                @change="handleImage()"
                id="file_patient"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="demo-inline-spacing">
            <h4>Tipo:</h4>
            <b-form-radio
                v-model="file_send.type_file"
                value="MODIFICATION"
            >
              Modificación
            </b-form-radio>
            <b-form-radio
                v-model="file_send.type_file"
                value="NEW"
            >
              Nuevo
            </b-form-radio>
          </div>
        </b-col>

        <b-col cols="12">

            <VoiceInput     label="Comentario" placeholder="Comentario" rows="2" id="textarea-1" v-model="file_send.comment" />


        </b-col>
        <b-col cols="12">
          <b-button
              @click="createFile"
              style="width: 200px"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
          >
            Guardar
          </b-button>

        </b-col>

      </b-row>
    </b-modal>
    <b-tabs style="margin-top: 30px">
      <b-tab active title="Archivos">
        <b-row class="mt-2 mb-3">
          <b-col
              lg="12"
          >
            <h2 style="text-align: center">{{ title }}</h2>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-3" lg="12" v-if="files.length === 0">
          <b-col

          >
            <h3 style="text-align: center">Aún no has subido ningún archivo</h3>
            <h4 style="text-align: center">Sube tu primer Archivo Haciendo Click en el botón</h4>
            <div align="center">

              <b-button
                  @click="showModalFiles"
                  style="width: 200px"
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
              >
                Subir Archivo
              </b-button>
            </div>
          </b-col>

    </b-row>
    <b-row v-else>
      <b-col
          lg="3"
      >
        <b-button
            @click="showModalFiles"
            style="width: 200px"
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
        >
          Subir Archivo
        </b-button>
        <div class="mt-3">
          <b-list-group>
            <b-list-group-item style="cursor:pointer;" v-for="(a, idx) in files" :key="idx">
              <b-row @click="showOneFile(a.id)">
                <b-col lg="12" >
                  <div align="center">
                    <feather-icon icon="FolderIcon" size="40"/>
                    <strong style="margin-left: 10px">Titulo: </strong>{{ a.title }}
                  </div>
                </b-col>
                <b-col lg="12">
                  <p style="text-align: center"><strong>Comentarios:</strong> <br>
                    {{ a.comment }} <br v-if="a.cycle"> <span v-if="a.cycle">Ciclo: <strong>{{
                        a.cycle
                      }}</strong></span>
                    <br>
                    <span v-if="a.type_file">Tipo: <strong>{{ typeFile(a.type_file) }}</strong></span> <br>
                    <span v-if="a.date_cycle">Fecha de ciclo: <strong>{{ dateStringMx(a.date_cycle) }}</strong></span>
                    <br>
                    <span v-if="a.type_document">Documento: <strong>{{ a.type_document }}</strong></span>
                  </p>
                      <p style="text-align: center">
                        {{ resHoursOdooFormatSix(a.createdAt) }}
                      </p>

                    </b-col>

                  </b-row>
                  <b-row>
                    <b-col lg="12">
                      <div align="center">
                        <a @click="showFile(a.id, data_patient.name)"
                           style="text-align: center; cursor: pointer">
                          <feather-icon icon="SearchIcon" style="color: green" size="20"/>
                          Visualizar Archivo</a> <br>
                      </div>

                    </b-col>
                  </b-row>

                </b-list-group-item>
              </b-list-group>
            </div>

          </b-col>
          <b-col cols="9" v-if="viewFilePreview">
            <img style="width: 100%" v-if="viewFilePreviewType" :src="viewFilePreview"/>
            <iframe style="width: 100%; height: 1000px" v-else :src="viewFilePreview"/>
          </b-col>
          <b-col cols="9" v-if="viewFile">
            <br>
            <b-row>
              <b-col cols="12" class="mb-4">
                <b-form-group
                    label="Renombrar archivo (solo nombre sin tipo de archivo)"
                >
                  <b-form-input
                      v-model="nameFile"
                      placeholder="Archivo"
                  />
                </b-form-group>
                <div align="center">
                  <b-button
                      @click="changeNameFile"
                      style="width: 200px"
                      class="mt-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                  >
                    Guardar
                  </b-button>
                </div>


          </b-col>
            <b-col cols="4">
            <div align="center">
              <h3>Información</h3><br>
              <h4>Nombre: <strong>{{ one.title }}</strong></h4>
              <h4>Comentario: <strong>{{ one.comment }}</strong></h4>
              <h4 v-if="one.cycle">Ciclo: <strong>{{ one.cycle }}</strong></h4>
              <h4 v-if="one.type_file">Tipo: <strong>{{ typeFile(one.type_file) }}</strong></h4>
              <h4 v-if="one.date_cycle">Fecha de ciclo: <strong>{{ dateStringMx(one.date_cycle) }}</strong></h4>
              <h4 v-if="one.fee_cycle">Honorarios: $<strong>{{ one.fee_cycle }}</strong></h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div align="center">
              <h3>Descargar Archivo</h3>


                  <a @click="downloads(one.id, one.file,one.title, data_patient.name)"
                     style="text-align: center">
                    <feather-icon icon="FolderIcon" size="100"/>
                    <br>
                    {{ one.file }}
                  </a>

                </div>
              </b-col>
              <b-col cols="4">
                <div align="center">
                  <h3>Borrar archivo: </h3>
                  <a style="text-align: right" @click="deleteFile(one.id)">
                    <feather-icon icon="TrashIcon" style="color: darkred" size="50"/>
                  </a>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Archivos de consultas" @click="getConsultations">
        <b-row>
          <b-col cols="12" lg="3">
            <div class="mt-3">
              <b-list-group>
                <b-list-group-item
                    style="cursor: pointer"
                    v-for="(a, idx) in consults"
                    :key="idx"
                    @click="viewConsultation(a.id)"
                >
                  {{ resHoursOdooFormat(a.createdAt) }}(ver archivo) <br/>
                  <span v-if="a.HrEmployee">{{ a.HrEmployee.name }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
          <b-col cols="12" lg="9">
            <b-row>
              <b-col
                  v-if="FilesDownloadList"
                  v-for="(b, idx) in FilesDownloadList"
                  :key="idx"
                  cols="4"
                  class="mt-1"
              >
                <a
                    @click="deleteFileConsultant(b.id)"
                    style="text-align: right"
                >
                  <feather-icon
                      icon="TrashIcon"
                      style="color: darkred"
                      size="20"
                  />
                </a>
                <a @click="downloadFileOne(b.id)" style="text-align: center">
                  <feather-icon icon="FolderIcon" size="100"/>
                  <br/>
                  <span style="text-align: center">{{ b.name }} </span>
                  <br/>
                  <feather-icon
                      icon="DownloadIcon"
                      style="color: black"
                      size="20"
                  />
                  Descargar Archivo
                </a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm, BFormFile,
  BFormGroup,
  BFormInput, BFormRadio, BFormSelect,
  BFormTextarea,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink,
  BRow,
  BModal,
  VBModal, BOverlay, BListGroup, BListGroupItem,
  BFormCheckbox, BFormCheckboxGroup
} from "bootstrap-vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Ripple from "vue-ripple-directive";
import { createGlobalState } from '@vueuse/core';
import VoiceInput from "@/components/VoiceInput.vue";

export default {
  name: "FilesPatient",
  components: {
    VoiceInput,
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    title: String,
    data_patient: Object
  },
  watch: {
    async data_patient() {
    }
  },
  async created() {
    await this.findFiles()
  },
  data() {
    return {
      files: [],
      consults: [],
      consultations: {},
      show: false,
      viewFile: false,
      FilesDownloadList: [],
      viewFilePreview: null,
      nameFile: null,
      viewFilePreviewType: null,
      one: {
        id: null,
        comment: null,
        file: null,
        is_deleted: null,
        type_file: null,
        cycle: null,
        date_cycle: null,
        fee_cycle: null,
        name_file: null,
        patient_id: null,
        share_patient: null,
        type: 'all',
        title: null,
      },
      type_document_options: [
          {value: '', text: 'Seleccione una opción'},
          {value: 'Indicaciones', text: 'Indicaciones'},
          {value: 'Informe Médico', text: 'Informe Médico'},
          {value: 'Laboratoriales', text: 'Laboratoriales'},
      ],        
      file_send: {
        id: null,
        title: null,
        type: 'all',
        cycle: null,
        date_cycle: null,
        fee: true,
        fee_cycle: null,
        type_document: null,
        type_file: 'NEW',
        name_file: null,
        comment: null,
        file: null,
        patient_id: 0
      }
    }
  },
  methods: {
    ...mapActions('patient', ['findPatientAllFiles', 'createPatientFiles', 'findPatientOneFiles', 'downloadFiPatientOne', 'updateFileHistory', 'downloadFiPatientOnePreview', 'updateNameFile', "deleteFileRequest",
      "downloadFiConsultations",'findPatientAllConsultations','findPatientOneConsultations']),
    async deleteFileConsultant(id) {
      this.show = true;
      const response = await this.deleteFileRequest(id);
      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se Borro el archivo correctamente`,
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.show = false;
        await this.viewConsultation(this.consultations.id);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error al borrar el archivo`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async downloadFileOne(id) {
      await this.downloadFiConsultations(id);
    },
    async viewConsultation(id) {
      this.consultations = {};
      this.consultations = await this.findPatientOneConsultations(id);
      this.FilesDownloadList = [];
      if (this.consultations.ClinicConsultationsFiles?.length > 0){
        for (const a of this.consultations.ClinicConsultationsFiles){
          this.FilesDownloadList.push({
            id:a.id,
            name:a.name
          })
        }


      }
      this.updateInformation = true;

    },
    async getConsultations() {
      this.show = true;
      this.consults = []

      const response  = await this.findPatientAllConsultations(this.data_patient.id);


        for (const a of response){
          if (a.ClinicConsultationsFiles && a.ClinicConsultationsFiles?.length > 0){
          this.consults.push(a)
        }
      }

      this.show = false;
    },
    async downloads(id, file, name, partner) {

      const [, type] = file.split(';')[0].split('/');
      const send = {
        id,
        type,
        name,
        partner,
      }
      await this.downloadFiPatientOne(send);
    },
    async changeNameFile() {
      if (!this.nameFile) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'titulo requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.show = true;
      const send = {
        file: this.nameFile
      }

      const response = await this.updateNameFile({id: this.one.id, data: send});
      if (response) {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se cambio el nombre correctamente`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.showOneFile(this.one.id)
        this.show = false;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al cambiar el nombre',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.show = false;
      }
    },
    async showFile(id, partner) {
      this.show = true
      const response = await this.findPatientOneFiles({id: id, patient: this.data_patient.id});
      const title = response.title;
      const [, type] = response.file.split(';')[0].split('/');
      const send = {
        id,
        type,
        title,
        partner,
      }
      const responseData = await this.downloadFiPatientOnePreview(send);
      this.viewFilePreview = this.addBase64Prefix(responseData.msg, responseData.type)
      this.viewFilePreviewType = responseData.type !== 'pdf' ? true : false;
      this.viewFile = false
      this.show = false
    },

    async findFiles() {
      this.files = await this.findPatientAllFiles({id: this.data_patient.id, type: 'all'});
    },
    resetModal() {
    },
    async showOneFile(id) {
      const response = await this.findPatientOneFiles({id: id, patient: this.data_patient.id});
      if (response) {
        this.one = response
        this.viewFile = true
        this.viewFilePreviewType = null
        this.viewFilePreview = null
        this.nameFile = this.nameFileExtract(response.file)
      }
    },
    async deleteFile(id) {
      this.$swal({
        title: 'Desea continuar?',
        text: "Seguro que quieres borrar el archivo?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.show = true
          const send = {
            is_deleted: true
          }
          const response = await this.updateFileHistory({id: id, data: send});
          if (response) {
            this.$swal({
              icon: 'success',
              title: 'Borro!',
              text: 'Su archivo se borro correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            await this.findFiles()
            this.show = false
            this.viewFile = false
            this.file_send = {
              id: null,
              title: null,
              type: 'all',
              comment: null,
              file: null,
              patient_id: 0
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'error al borrar archivo consulto con el administrador',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
            this.show = false
          }

        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })

    },
    nameFileExtract(data) {
      const newName = data.split('.')
      return newName[0]

    },
    async createFile() {
      this.file_send.patient_id = this.data_patient.id;
      if (!this.file_send.file) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Archivo requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.file_send.cycle && this.file_send.type_document === 'Indicaciones') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ciclo requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.file_send.date_cycle && this.file_send.type_document === 'Indicaciones') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Fecha de ciclo requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      if ((!this.file_send.fee_cycle && !this.file_send.fee) && this.file_send.type_document === 'Indicaciones') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'El campo de honorarios no puede ir vacío',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.file_send.type_document) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tipo de documento requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.file_send.title) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'titulo requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      await this.$bvModal.hide('modal-prevent-closing')
      this.show = true
      const response = await this.createPatientFiles(this.file_send)
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se guardo el archivo`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        await this.findFiles()
        this.file_send = {
          id: null,
          title: null,
          comment: null,
          type: 'all',
          file: null,
          patient_id: 0
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al subir archivo consulte al administrador',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.show = false
        await this.$bvModal.show('modal-prevent-closing')
      }

    },
    async handleImage() {
      const file = document.querySelector(`#file_patient`).files[0]

      const file_convert = await this.getBase64(file)
      file_convert.onload = (event) => {
        this.file_send.file = event.target.result;
        this.file_send.name_file = file.name
      };
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async showModalFiles() {
      await this.$bvModal.show('modal-prevent-closing')


    },
  }
}
</script>

<style scoped>

</style>
